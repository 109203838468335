<template>
  <section if="information-page">
    <div
      v-if="info"
      class="defaultcontent-grid"
    >
      <div class="singlepage-grid-primary">
        <MarkedParser
          v-if="$i18n.locale === 'nb'"
          :marked-content="info.aboutname_content_no"
          class="markdown"
        />
        <MarkedParser
          v-else
          :marked-content="info.aboutname_content_en"
          class="markdown"
        />
      </div>

      <BaseSidebar />

      <BaseInfoBox />
    </div>
  </section>
</template>

<script>
import MarkedParser from '@/components/MarkedParser'
import BaseInfoBox from '@/components/base/BaseInfoBox'
import BaseSidebar from '@/components/base/BaseSidebar'
import {format} from 'date-fns';
import gql from 'graphql-tag';

const getInfo = gql `
    query History {
        info {
            aboutname_content_no
            aboutname_content_en
        }
    }
    `

export default {
    apollo: {
        info: {
            query: getInfo
        }
    },
    metaInfo() {
        return { 
            title: this.$t("nav.aboutName"),
        }
    },
    components: {
        MarkedParser,
        BaseSidebar,
        BaseInfoBox
    },
    computed: {
        today() {
            return format(new Date(), "dd.MM.yyyy");
        },
    }
};
</script>
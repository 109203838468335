<template>
  <section
    v-if="press"
    id="single-page"
  >
    <BaseBasicPage :page-content="press" />
  </section>
</template>

<script>
import BaseBasicPage from '@/components/base/BaseBasicPage'
import gql from 'graphql-tag'

export default {
    apollo: {
        press: {
            query: gql `
            query getPress {
                press {
                  content_en
                  content_no
                  photo_cover {
                      formats
                      caption
                  }
                  photo_gallery {
                      formats
                      caption
                  }
                  press_contacts {
                      first_name
                      last_name
                      function_en
                      function_no
                      mail
                      primary_phone
                      secondary_phone
                  }
                }
            }
        `
        }
    },
    metaInfo() {
        return { title: this.$t("nav.press") }
    },
    components: {
        BaseBasicPage
    }
}
</script>
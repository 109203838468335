<template>
  <section id="single-page">
    <BaseBasicPage :page-content="publicSpace" />
  </section>
</template>

<script>
import BaseBasicPage from '@/components/base/BaseBasicPage'
import gql from 'graphql-tag'

export default {
    apollo: {
        publicSpace: {
            query: gql `
            query publicSpace {
                publicSpace {
                  content_en
                  content_no
                  photo_cover {
                      formats
                      caption
                  }
                }
            }
        `
        }
    },
    metaInfo() {
        return { 
            title: this.$t("nav.publicSpace"),
        }
    },
    components: {
        BaseBasicPage
    }
}
</script>
<template>
  <section
    v-if="history"
    id="history-page"
  >
    <div class="defaultcontent-grid">
      <PhotoCover :photo-cover="history.photo_cover" />
    
      <div class="singlepage-grid-primary">
        <MarkedParser
          v-if="$i18n.locale === 'nb'"
          :marked-content="history.content_no"
          class="markdown"
        />
        <MarkedParser
          v-else
          :marked-content="history.content_en"
          class="markdown"
        />
      </div>

      <div
        v-if="history.press_archive.length > 0"
        id="archive"
        class="defaultcontent-grid--fullwidth listcomponent-order"
      >
        <h1 class="section-header">
          {{ $t('headers.pressArchive') }}
        </h1>
        <div id="collection">
          <ListComponent :list-content="history.press_archive" />
        </div>
      </div>

      <BaseSidebar />

      <BaseInfoBox />
    </div>
  </section>
</template>

<script>
import MarkedParser from '@/components/MarkedParser'
import BaseInfoBox from '@/components/base/BaseInfoBox'
import PhotoCover from '@/components/PhotoCover'
import BaseSidebar from '@/components/base/BaseSidebar'
import ListComponent from '@/components/ListComponent'

import {format} from 'date-fns';
import gql from "graphql-tag";

const getHistory = gql `
    query History {
        history {
            content_en
            content_no
            photo_cover {
                formats
            }
            press_archive {
                id
                published_date
                title_en
                title_no
                author
                url
                file {
                    url
                }
            }
        }
    }   
`
export default {
    apollo: {
        history: {
            query: getHistory
        }
    },
    components: {
        MarkedParser,
        PhotoCover,
        BaseSidebar,
        BaseInfoBox,
        ListComponent
    },
    data() {
        return {
            api_url: process.env.VUE_APP_STRAPI_API_URL
        };
    },
    metaInfo() {
        return { 
            title: this.$t("nav.history"),
        }
    },
    computed: {
        today() {
            return format(new Date(), "dd.MM.yyyy");
        },
    }
};
</script>
<template>
  <section
    v-if="info"
    id="contact-page"
  >
    <div class="defaultcontent-grid">
      <div class="singlepage-grid-primary">
        <div class="contact-card-grid">
          <BaseTeamCard
            v-for="person in info.teammembers"
            :key="person.id"
            :card-content="person"
          />
        </div>
    
        <div class="simple-twocolumn-list">
          <ul
            v-if="info.receptionmembers"
            id="receptionmembers"
          >
            <li>
              <h1
                v-if="info.receptionmembers"
                class="section-header"
              >
                {{ $t('headers.reception') }}
              </h1>
            </li>
            <li
              v-for="person in info.receptionmembers"
              :key="person.name"
            >
              <p class="title-default">
                {{ person.first_name }} {{ person.last_name }}
              </p>
              <span v-if="$i18n.locale == 'nb'">{{ person.function_no }}</span>
              <span v-else>{{ person.function_en }}</span>
            </li>
          </ul>

          <ul
            v-if="info.boardmembers"
            id="boardmembers"
          >
            <li>
              <h1
                v-if="info.boardmembers"
                class="section-header"
              >
                {{ $t('headers.board') }}
              </h1>
            </li>
            <li
              v-for="person in info.boardmembers"
              :key="person.name"
            >
              <p class="title-default">
                {{ person.first_name }} {{ person.last_name }}
              </p>
              <span v-if="$i18n.locale == 'nb'">{{ person.function_no }}</span>
              <span v-else>{{ person.function_en }}</span>
            </li>
          </ul>
    
          <ul
            v-if="info.supervisorymembers"
            id="supervisorymembers"
          >
            <li>
              <h1
                v-if="info.supervisorymembers"
                class="section-header"
              >
                {{ $t('headers.supervisory') }}
              </h1>
            </li>
            <li
              v-for="person in info.supervisorymembers"
              :key="person.name"
            >
              <p class="title-default">
                {{ person.first_name }} {{ person.last_name }}
              </p>
              <span v-if="$i18n.locale == 'nb'">{{ person.function_no }}</span>
              <span v-else>{{ person.function_en }}</span>
            </li>
          </ul>
        </div>
      </div>
    
      <BaseSidebar />
      <BaseInfoBox />
    </div>
  </section>
</template>

<script>
import BaseInfoBox from '@/components/base/BaseInfoBox'
import BaseSidebar from '@/components/base/BaseSidebar'
import BaseTeamCard from '@/components/base/BaseTeamCard'
import { format } from 'date-fns';
import gql from "graphql-tag";
import _ from "lodash";

const getInfo = gql `
    query Info {
        info {
            teammembers {
                first_name
                last_name
                function_en
                function_no
                photo_portrait {
                    formats
                }
                mail
                primary_phone
                secondary_phone
            }
            receptionmembers {
                first_name
                last_name
                function_en
                function_no
            }
            boardmembers {
                first_name
                last_name
                function_en
                function_no
            }
            supervisorymembers {
                first_name
                last_name
                function_en
                function_no
            }

        }
    }
    `

export default {
    apollo: {
        info: {
            query: getInfo
        }
    },
    components: {
        BaseSidebar,
        BaseInfoBox,
        BaseTeamCard
    },
    data() {
        return {
            api_url: process.env.VUE_APP_STRAPI_API_URL
        };
    },
    metaInfo() {
        return { title: this.$t("nav.contact") }
    },
    computed: {
        today() {
            return format(new Date(), "dd.MM.yyyy");
        },
        sortedReports() {
            return _.orderBy(this.info.annual_reports, 'year', 'desc')
        },
        sortedMembers: function() {
            return _.orderBy(this.info.teammembers, 'last_name')
        }
    },

};
</script>
<template>
    <div id="menu">
        <ul v-for="e in menuContent" :key="e.sectiontitle_en" class="menusection">
            <h2 v-if="$i18n.locale === 'en'" class="sectiontitle">
                {{ e.sectiontitle_en }}
            </h2>
            <h2 v-else class="sectiontitle">
                {{ e.sectiontitle_no }}
            </h2>
    
            <li v-for="item in e.menu_item" :key="item.title_en" class="menu-bar">
                <p>
                    <span v-if="$i18n.locale === 'en'">{{ item.title_en }}
                <i
                  v-if="item.additional_info_en && $route.path == '/cafe'"
                  class="additional-info--btn"
                  @click.self="toggleElement"
                ><span>i</span></i>
                    </span>
    
                    <span v-else>{{ item.title_no }}
                <i
                  v-if="item.additional_info_en && $route.path == '/cafe'"
                  class="additional-info--btn"
                  @click.self="toggleElement"
                ><span>i</span></i>
                    </span>
    
                    <span v-if="item.price_no != null">{{ item.price_no }} NOK</span>
                    <span v-else>{{ $t('info.freeEntry') }}</span>
                </p>
    
                <div v-if="item.additional_info_en" id="additional-info" class="closed">
                    <p v-if="$i18n.locale === 'en'">
                        {{ item.additional_info_en }}
                    </p>
                    <p v-else>
                        {{ item.additional_info_no }}
                    </p>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        menuContent: {
          type: Array,
          default: () => ([])
        }
    },
    methods: {
        toggleElement: function(event) {
            if (event) {
                const dropdown = event.target.parentNode.parentNode.nextSibling;
                if (dropdown.classList.contains('closed')) {
                    dropdown.classList.toggle('closed');
                } else {
                    dropdown.classList.toggle('closed');
                }
            }
        }
    }
}
</script>
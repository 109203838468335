<template>
  <div id="information-multilingual">
    <div class="btn-selector">
      <button
        v-for="i in textCollection"
        :key="i.countrycode"
        :class="{ active: defaultLang === i.countrycode }"
        @click="changeLang(i.countrycode)"
      >
        {{ i.countrycode }}
      </button>
    </div>
    
    <TextContent
      :text-content="textCollection"
      :default-lang="defaultLang"
    />
  </div>
</template>

<script>
import TextContent from './MultilingualBComponent';

export default {
    components: {
        TextContent,
    },
    props: {
        textCollection: {
          type: Array,
          default: () => ([])
        }
    },
    data() {
        return {
            defaultLang: "no",
        }
    },
    methods: {
        changeLang(value) {
            this.defaultLang = value;
        }
    }
}
</script>
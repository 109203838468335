<template>
  <section v-if="spaceRental" id="single-page">
        <div class="defaultcontent-grid">
            <BaseGallery v-if="spaceRental.photo_gallery" :gallery-content="spaceRental.photo_gallery" />
            <div class="singlepage-grid-primary">

                <div class="rental-section">
                    <MarkedParser v-if="$i18n.locale === 'nb'" :marked-content="spaceRental.content_no" class="markdown md-no-margin" />
                    <MarkedParser v-else :marked-content="spaceRental.content_en" class="markdown md-no-margin" />
                </div>

                <div class="rental-section rental-cards"  v-if="spaceRental.rooms">
                    <div v-for="room in spaceRental.rooms" :key="room.id" class="card">
                        <p v-if="$i18n.locale === 'nb'" class="title-default">{{ room.title_no }}</p>
                        <p v-else class="title-default">{{ room.title_en }}</p>
                        <MarkedParser v-if="$i18n.locale === 'nb'" :marked-content="room.contentShort_no" class="markdown" />
                        <MarkedParser v-else :marked-content="room.contentShort_en" class="markdown" />
                        <p v-if="$i18n.locale === 'nb'" class="boldtext">Kapasitet for {{ room.capacity }} personer</p>
                        <p v-else class="boldtext">Capacity for {{ room.capacity }} people</p>
                    </div>
                </div>

                <div class="rental-section">
                    <h1 class="title-default">{{ $t('spacerental.headerPricing')}}</h1>
                    <MarkedParser v-if="$i18n.locale === 'nb'" :marked-content="spaceRental.pricing_text_no" class="markdown md-no-margin" />
                    <MarkedParser v-else :marked-content="spaceRental.pricing_text_en" class="markdown md-no-margin" />
                    <table class="default-table">
                        <tr v-for="price in spaceRental.pricing" :key="price.id">
                            <td v-if="$i18n.locale === 'nb'"> {{ price.day_type_no }}</td>
                            <td v-else> {{ price.day_type_en }}</td>
                            <td>{{ price.timeframe_start | formatTime }}–{{ price.timeframe_end | formatTime }}</td>
                            <td>{{ price.price_per_day }} {{ $t('spacerental.perDay') }}</td>
                            <td class="table-btn"><a :href="'mailto:booking@nitja.no?subject=[Forespørsel] ' + price.day_type_no + ' (' + $options.filters.formatTime(price.timeframe_start) + '–' + $options.filters.formatTime(price.timeframe_end) + ')'">{{ $t('spacerental.sendRequest') }}</a></td>
                        </tr>
                    </table>
                    <div class="default-table--note">
                        <p class="metatext">
                            {{ $t('spacerental.tableNote')}}
                        </p>
                    </div>
                </div>

                <div class="rental-section">
                    <h1 class="title-default">{{ $t('spacerental.headerOther')}}</h1>
                    <MarkedParser v-if="$i18n.locale === 'nb'" :marked-content="spaceRental.other_rentals_no" class="markdown md-no-margin" />
                    <MarkedParser v-else :marked-content="spaceRental.other_rentals_en" class="markdown md-no-margin" />
                    <div class="btn-fullw-nrml square-btn--default">
                        <a :href="'mailto:booking@nitja.no?subject=[Andre utleiemuligheter]'">{{ $t('spacerental.otherInquiries')}}</a></div>
                    
                </div>
            </div>

        </div>
  </section>
</template>

<script>
import MarkedParser from '@/components/MarkedParser'
import BaseGallery from '@/components/base/BaseGallery'
import gql from 'graphql-tag'

export default {
    apollo: {
        spaceRental: {
            query: gql `
            query spaceRental {
                spaceRental {
                  content_en
                  content_no
                  photo_cover {
                      formats
                      caption
                  }
                  photo_gallery {
                      formats
                      caption
                  }
                  rooms {
                      title_no
                      title_en
                      contentShort_no
                      contentShort_en
                      capacity
                  }
                  pricing_text_no
                  pricing_text_en
                  pricing {
                      day_type_no
                      day_type_en
                      timeframe_start
                      timeframe_end
                      price_per_day
                  }
                  other_rentals_no
                  other_rentals_en
                }
            }
        `
        }
    },
    metaInfo() {
        return { 
            title: this.$t("nav.renting"),
        }
    },
    components: {
        MarkedParser,
        BaseGallery
    }
}
</script>
<template>
  <section
    v-if="openCall"
    id="single-page"
  >
    <BaseBasicPage :page-content="openCall" />
  </section>
</template>

<script>
import BaseBasicPage from '@/components/base/BaseBasicPage'
import gql from 'graphql-tag'

export default {
    apollo: {
        openCall: {
            query: gql `
            query OpenCall {
                openCall {
                  content_no
                  content_en
                  contentShort_no
                  contentShort_en
                  photo_cover {
                      formats
                      caption
                  }
                }
            }
        `
        }
    },
    components: {
        BaseBasicPage
    },
    data() {
        return {
            ogDesc: '',
        };
    },
    metaInfo() {
        return {
            title: this.$t("nav.openCall"),
            meta: [
                { property: 'og:title', content: this.$t("nav.openCall") + ' | Nitja senter for samtidskunst', vmid: 'og:title' },
                { property: 'og:description', content: this.ogDesc, vmid: 'og:description' },
                { property: 'og:type', content: 'article', vmid: 'og:type' },
                { property: 'article:publisher', content: 'https://www.nitja.no/', vmid: 'article:publisher' },
                { property: 'twitter:title', content: this.$t("nav.openCall") + ' | Nitja senter for samtidskunst', vmid: 'twitter:title' },
                { property: 'twitter:description', content: this.ogDesc, vmid: 'twitter:description' },
            ]
        }
    },
    updated() {
        let pageDescription = this.openCall.contentShort_no;
        this.ogDesc = pageDescription 
    }
}
</script>
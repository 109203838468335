<template>
    <div v-if="info" class="singlepage-grid-secondary metatext">
        <ul id="address">
            <p v-if="$i18n.locale === 'en'">
                {{ info.name_en }}
            </p>
            <p v-else>
                {{ info.name_no }}
            </p>
            <br/>
            <p>{{ info.phone }}</p>
            <p><a :href="'mailto:' + info.mail">{{ info.mail }}</a></p>
            <br />
            <p>{{ info.streetname }}</p>
            <p>{{ info.zipcode }}, {{ info.city }}</p>
            <p v-if="$i18n.locale === 'en'">
                {{ info.country_en }}
            </p>
            <p v-else>
                {{ info.country_no }}
            </p>
        </ul>
    
        <ul id="socials">
            <li v-if="info.facebook">
                <a :href="info.facebook | cleanUrl" target="_blank" rel="noreferrer noopener">
              <span><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-facebook" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2F2F2F" fill="none"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" /></svg></span>
            </a>
            </li>
            <li v-if="info.instagram">
                <a :href="info.instagram | cleanUrl" target="_blank" rel="noreferrer noopener">
              <span><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-instagram" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2F2F2F" fill="none"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><rect x="4" y="4" width="16" height="16" rx="4" /><circle cx="12" cy="12" r="3" /><line x1="16.5" y1="7.5" x2="16.5" y2="7.501" /></svg></span>
            </a>
            </li>
            <li v-if="info.twitter">
                <a :href="info.twitter | cleanUrl" target="_blank" rel="noreferrer noopener">
              <span><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-twitter" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2F2F2F" fill="none"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M22 4.01c-1 .49-1.98.689-3 .99-1.121-1.265-2.783-1.335-4.38-.737S11.977 6.323 12 8v1c-3.245.083-6.135-1.395-8-4 0 0-4.182 7.433 4 11-1.872 1.247-3.739 2.088-6 2 3.308 1.803 6.913 2.423 10.034 1.517 3.58-1.04 6.522-3.723 7.651-7.742a13.84 13.84 0 0 0 .497 -3.753C20.18 7.773 21.692 5.25 22 4.009z" /></svg></span>
            </a>
            </li>
            <li v-if="info.youtube">
                <a :href="info.youtube | cleanUrl" target="_blank" rel="noreferrer noopener">
              <span><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-youtube" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2F2F2F" fill="none"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><rect x="3" y="5" width="18" height="14" rx="4" /><path d="M10 9l5 3l-5 3z" /></svg></span>
            </a>
            </li>
            <li v-if="info.tripadvisor">
                <a :href="info.tripadvisor | cleanUrl" target="_blank" rel="noreferrer noopener">
              <span><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-plane" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2F2F2F" fill="none"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M16 10h4a2 2 0 0 1 0 4h-4l-4 7h-3l2 -7h-4l-2 2h-3l2-4l-2 -4h3l2 2h4l-2 -7h3z" /></svg></span>
            </a>
            </li>
        </ul>
    
        <ul id="timetable" class="timetable">
            <li v-for="day in info.openinghours" :key="day.day_en">
                <span v-if="$i18n.locale === 'en'">{{ day.day_en }}</span>
                <span v-else>{{ day.day_no }}</span>
                <span v-if="day.opening">{{ day.opening | formatTime }}–{{ day.closing | formatTime }}</span>
                <span v-else>{{ $t("info.closed") }}</span>
            </li>
        </ul>
    
        <MenuComponent :menu-content="ticket.ticket_pricing" />
    
        <ul v-if="info.reports.length > 0" id="reports" class="downloadlist">
            <p class="metatext-header">
                {{ $tc('info.reports', info.reports.length) }}
            </p>
            <li v-for="file in info.reports" :key="file.name">
                <a :href="api_url + file.file.url" download class="download-link">{{ file.year }}
              <span>
                <svgDownload />  
              </span>
            </a>
            </li>
        </ul>
    
        <ul>
            <li>
                <router-link to="/privacypolicy" class="metatext-header title-hover">
                    {{ $t("nav.privacyPolicy") }}
                </router-link>
            </li>
            <li>
                <router-link to="/visions" class="metatext-header title-hover">
                    {{ $t("nav.visions") }}
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
import MenuComponent from '@/components/MenuComponent'
import svgDownload from '@/components/base/svgDownload'
import gql from "graphql-tag"

const getInfo = gql `
query Info {
        info {
            name_en
            name_no
            streetname
            zipcode
            city
            country_en
            country_no
            phone
            mail
            facebook
            instagram
            twitter
            youtube
            tripadvisor
            openinghours {
                day_en
                day_no
                opening
                closing
            }
            reports {
                year
                file {
                    url
                }
            }
        }
    }
`;
const getTickets = gql `
    query Tickets {
        ticket {
            ticket_pricing {
                sectiontitle_en
                sectiontitle_no
                menu_item {
                    title_en
                    title_no
                    additional_info_en
                    additional_info_no
                    price_no
                }
            }
        }
    }
`;

export default {
    apollo: {
        info: {
            query: getInfo
        },
        ticket: {
            query: getTickets
        }
    },
    components: {
        MenuComponent,
        svgDownload
    },
    props: {
        sidebarContent: {
          type: Object,
          default: () => ({})
        }
    },
    data() {
        return {
            ticket: [{ ticket_pricing: [] }],
            api_url: process.env.VUE_APP_STRAPI_API_URL,
        };
    },
    methods: {
        reverseMessage: function(mail) {
            const mailR = mail.split('').reverse().join('')
            return mailR
        },
    }
}
</script>
<template>
  <section
    v-if="info"
    id="information-page"
  >
    <div class="defaultcontent-grid">
      <PhotoCover :photo-cover="info.photo_cover" />
    
      <div class="singlepage-grid-primary">
        <!-- <MultilingualAComponent :textCollection="info.multilingual_text" /> -->
        <MarkedParser
          v-if="$i18n.locale === 'nb'"
          :marked-content="info.content_no"
          class="markdown"
        />
        <MarkedParser
          v-else
          :marked-content="info.content_en"
          class="markdown"
        />
      </div>

      <BaseSidebar />

      <BaseInfoBox />
    </div>
  </section>
</template>

<script>
import MarkedParser from '@/components/MarkedParser'
import BaseInfoBox from '@/components/base/BaseInfoBox'
import PhotoCover from '@/components/PhotoCover'
import BaseSidebar from '@/components/base/BaseSidebar'
// import MultilingualAComponent from '@/components/MultilingualAComponent'
import { format } from 'date-fns'
import gql from 'graphql-tag'
import _ from 'lodash'

const getInfo = gql `
    query Info {
        info {
            content_no
            content_en
            infobox_no
            infobox_en
            multilingual_text {
                countrycode
                content
            }
            photo_cover {
                formats
            }
        }
    }
    `

export default {
    apollo: {
        info: {
            query: getInfo
        }
    },
    components: {
        MarkedParser,
        PhotoCover,
        BaseSidebar,
        // MultilingualAComponent,
        BaseInfoBox,
        
    },
    data() {
        return {
            api_url: process.env.VUE_APP_STRAPI_API_URL
        };
    },
    computed: {
        today() {
            return format(new Date(), "dd.MM.yyyy");
        },
        sortedReports() {
            return _.orderBy(this.info.annual_reports, 'year', 'desc')
        }
    }
};
</script>
<template>
    <div>
        <div v-for="i in textContent" :key="i.countrycode">
            <div v-if="i.countrycode == defaultLang" id="multilingual-content" :data-lang="i.countrycode">
                <h1>{{ i.content }}</h1>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        textContent: {
            type: Array,
            default: () => ([]),
        },
        defaultLang: {
            type: String,
            default: '',
        }
    }
}
</script>

<template>
  <section
    v-if="ticket"
    id="ticket-page"
  >
    <div class="defaultcontent-grid">
      <PhotoCover :photo-cover="ticket.photo_cover" />
    
      <div class="singlepage-grid-primary">
        <div
          id="ticket-pricing"
          class="simple-twocolumn-list"
        >
          <ul
            v-for="e in ticket.ticket_pricing"
            :key="e.sectiontitle_en"
            class="menusection"
          >
            <li class="menu-bar">
              <h2
                v-if="$i18n.locale === 'en'"
                class="sectiontitle title-default"
              >
                {{ e.sectiontitle_en }}
              </h2>
              <h2
                v-else
                class="sectiontitle title-default"
              >
                {{ e.sectiontitle_no }}
              </h2>
            </li>
            <li
              v-for="item in e.menu_item"
              :key="item.title_en"
              class="menu-bar"
            >
              <div id="ticket-info">
                <p>
                  <svg
                    v-if="item.ticket_hex_color === 'ffffff'"
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-circle"
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    :fill="'#' + item.ticket_hex_color"
                    stroke-width=".5"
                    stroke="#000"
                  >
                    <path
                      stroke="none"
                      d="M0 0h24v24H0z"
                      fill="none"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      r="9"
                    />
                  </svg>
                  <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-circle"
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    :fill="'#' + item.ticket_hex_color"
                  >
                    <path
                      stroke="none"
                      d="M0 0h24v24H0z"
                      fill="none"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      r="9"
                    />
                  </svg>
                  <span v-if="$i18n.locale === 'en'">{{ item.title_en }}</span>
                  <span v-else>{{ item.title_no }}</span>
                </p>
                <p>
                  <span v-if="item.price_no != null">{{ item.price_no }} NOK</span>
                  <span v-else>{{ $t('info.freeEntry') }}</span>
                </p>
              </div>
              <div
                v-if="item.additional_info_en"
                id="additional-info"
                class="opened metatext"
              >
                <p v-if="$i18n.locale === 'en'">
                  {{ item.additional_info_en }}
                </p>
                <p v-else>
                  {{ item.additional_info_no }}
                </p>
              </div>
            </li>
          </ul>
        </div>
                
        <MarkedParser
          v-if="$i18n.locale === 'nb'"
          :marked-content="ticket.content_no"
          class="markdown"
        />
        <MarkedParser
          v-else
          :marked-content="ticket.content_en"
          class="markdown"
        />

        <ul id="ticket-cards">
          <li
            v-for="block in ticket.special_tickets"
            :key="block.id"
            class="ticket-card card-hover"
          >
            <div class="ticket-card--left">
              <h2>
                <span v-if="$i18n.locale === 'nb'">{{ block.name_no }}</span>
                <span
                  v-else
                  class="title-default"
                >{{ block.name_en }}</span>
              </h2>

              <p v-if="$i18n.locale === 'nb'">
                {{ block.description_no }}
              </p>
              <p v-else>
                {{ block.description_en }}
              </p>

              <ul
                v-if="block.special_ticket_perks.length >= 1"
                id="ticket-perks"
              >
                <li
                  v-for="perk in block.special_ticket_perks"
                  :key="perk.id"
                >
                  <span
                    v-if="$i18n.locale === 'nb'"
                    class="metatext"
                  >{{ perk.perk_no }}</span>
                  <span
                    v-else
                    class="metatext"
                  >{{ perk.perk_en }}</span>
                </li>
              </ul>
            </div>

            <div class="ticket-card--right">
              <h2
                v-if="block.price"
                class="ticket-card-price"
              >
                {{ block.price }}<span>NOK</span>
              </h2>

              <button :class="block.price ? 'square-btn--white' : 'square-btn--white no-price'">
                <a
                  v-if="$i18n.locale === 'nb'"
                  :href="block.url | cleanUrl"
                  target="_blank"
                  rel="noreferrer noopener"
                >{{ block.button_no }}</a>
                <a
                  v-else
                  :href="block.url | cleanUrl"
                  target="_blank"
                  rel="noreferrer noopener"
                >{{ block.button_en }}</a>
              </button>
            </div>
          </li>
        </ul>
      </div>
    
      <BaseSidebar />
      <BaseInfoBox />
    </div>
  </section>
</template>

<script>
import MarkedParser from '@/components/MarkedParser'
import BaseInfoBox from '@/components/base/BaseInfoBox'

import PhotoCover from '@/components/PhotoCover'
import BaseSidebar from '@/components/base/BaseSidebar'
import { format } from 'date-fns'
import gql from 'graphql-tag'
import _ from 'lodash'

const getTickets = gql `
    query Tickets {
        ticket {
            content_no
            content_en
            photo_cover {
                formats
            }
            ticket_pricing {
                sectiontitle_en
                sectiontitle_no
                menu_item {
                    ticket_hex_color
                    title_en
                    title_no
                    additional_info_en
                    additional_info_no
                    price_no
                }
            }
            special_tickets {
                name_no
                name_en
                button_no
                button_en
                description_no
                description_en
                special_ticket_perks {
                    perk_no
                    perk_en
                }
                url
                price
            }
        }
    }
`;

const getInfo = gql `
    query Info {
        info {
            name_en
            name_no
            streetname
            zipcode
            city
            country_en
            country_no
            phone
            mail
            facebook
            instagram
            twitter
            youtube
            tripadvisor
            openinghours {
                day_en
                day_no
                opening
                closing
            }
            reports {
                year
                file {
                    url
                }
            }
        }
    }
    `

export default {
    apollo: {
        ticket: {
            query: getTickets
        },
        info: {
            query: getInfo
        },
    },
    components: {
        MarkedParser,
        PhotoCover,
        BaseSidebar,
        BaseInfoBox,
    },
    data() {
        return {
            api_url: process.env.VUE_APP_STRAPI_API_URL,
        };
    },
    computed: {
        today() {
            return format(new Date(), "dd.MM.yyyy");
        },
        sortedReports() {
            return _.orderBy(this.info.annual_reports, 'year', 'desc')
        }
    },
    methods: {
        toggleElement: function(event) {
            if (event) {
                const dropdown = event.target.parentNode.parentNode.nextSibling;
                if (dropdown.classList.contains('closed')) {
                    dropdown.classList.toggle('closed');
                } else {
                    dropdown.classList.toggle('closed');
                }
            }
        }
    }
};
</script>
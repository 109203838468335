<template>
  <section
    v-if="design"
    id="single-page"
  >
    <BaseBasicPage :page-content="design" />
  </section>
</template>

<script>
import BaseBasicPage from '@/components/base/BaseBasicPage'
import gql from 'graphql-tag'

export default {
    apollo: {
        design: {
            query: gql `
            query Design {
                design {
                  content_en
                  content_no
                  photo_cover {
                      formats
                      caption
                  }
                  photo_gallery {
                      formats
                      caption
                  }
                }
            }
        `
        }
    },
    metaInfo() {
        return { title: this.$t("nav.design") }
    },
    components: {
        BaseBasicPage
    }
}
</script>